import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import { useEffect, useState } from 'react';
import LocationBasedConversionRatePieChart from './LocationBasedConversionRatePieChart';
import SingleCountryConversionRatePieChart from './SingleCountryConversionRatePieChart';
import ChartWithConfig from '../../../../../../components/charts/ChartWithConfig';
import { locationBasedConversionRateDummyData } from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';

const MainOverallAndLocationBasedCRChart = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<string>(
    '?graph_type=conversion_rate&time_range=all&group_by=location'
  );
  const [response, setResponse] = useState<any>({ type: 'location', data: {} });
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  //Selected country
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const renderCharts = () => {
    if (selectedCountry === '') {
      return (
        <LocationBasedConversionRatePieChart
          apiResponse={response}
          setSelectedCountry={setSelectedCountry}
        />
      );
    } else if (selectedCountry !== '') {
      return <SingleCountryConversionRatePieChart apiResponse={response} />;
    }
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    if (selectedCountry && selectedCountry !== '') {
      setResponse({
        type: 'country',
        data: locationBasedConversionRateDummyData[selectedCountry],
      });
    } else {
      setResponse({
        type: 'location',
        data: locationBasedConversionRateDummyData,
      });
    }
  };

  useEffect(() => {
    if (selectedCountry === '') {
      setTitle(t('LocationBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnLocation'));
    } else if (selectedCountry !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedCountry}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedCountry}`);
    }
  }, [selectedCountry]);

  useEffect(() => {
    setResponse({ type: 'load', data: [] });

    if (selectedCountry && selectedCountry !== '') {
      setParams(
        `?graph_type=conversion_rate&time_range=all&group_by=location&group_by_value=${selectedCountry}`
      );
    } else {
      setParams(`?graph_type=conversion_rate&time_range=all&group_by=location`);
    }
  }, [selectedCountry]);

  useEffect(() => {
    getGraphData();
  }, [params]);

  const onBackButtonClick = () => {
    if (selectedCountry !== '') {
      setSelectedCountry('');
    }
  };

  useEffect(() => {
    if (selectedCountry !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedCountry]);

  return (
    <ChartWithConfig
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainOverallAndLocationBasedCRChart;
