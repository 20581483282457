import React from 'react';
import MainOverallAndLocationBasedCRChart from './ConversionRate/OverallAndLocationBased/MainOverallAndLocationBasedCRChart';
import OverallConversionRatePieChart from './ConversionRate/OverallAndLocationBased/OverallConversionRatePieChart';
import { useTranslation } from 'react-i18next';
import WeeklyConversionRateLineChart from './ConversionRate/Weekly/WeeklyConversionRateLineChart';
import MainDeviceBasedCRChart from './ConversionRate/DeviceBased/MainDeviceBasedCRChart';
import MainAverageCartValueChart from './AverageCartValue/MainAverageCartValueChart';
import MainCTRChart from './ClickThroughRate/MainCTRChart';
import MainTimeAndDayCRChart from './ConversionRate/TimeAndDay/MainTimeAndDayCRChart';
import MainUserDemographicsChart from './UserDemographics/MainUserDemographicsChart';

const DummyStats = () => {
  const { t } = useTranslation();
  return (
    <div className='chartLayout'>
      <h2 className='chartLayout-dummy-text'>{t('DummyStatsText')}</h2>
      <div className='chartLayout--content'>
        <div className='chartLayout--content-small'>
          <OverallConversionRatePieChart />
        </div>
        <div className='chartLayout--content-large'>
          <WeeklyConversionRateLineChart />
        </div>
      </div>

      <div className='chartLayout--content'>
        <div className='chartLayout--content-large'>
          <MainDeviceBasedCRChart />
        </div>
        <div className='chartLayout--content-small'>
          <MainOverallAndLocationBasedCRChart />
        </div>
      </div>

      <div className='chartLayout--content'>
        <div className='chartLayout--content-small'>
          <MainAverageCartValueChart />
        </div>
        <div className='chartLayout--content-large'>
          <MainCTRChart />
        </div>
      </div>

      <div className='chartLayout--content'>
        <div className='chartLayout--content-large'>
          <MainTimeAndDayCRChart timeFilter={'day_of_week'} />
        </div>
        <div className='chartLayout--content-small'>
          <MainTimeAndDayCRChart timeFilter={'time_of_day'} />
        </div>
      </div>

      <div className='chartLayout--content'>
        <MainUserDemographicsChart />
      </div>
    </div>
  );
};

export default DummyStats;
