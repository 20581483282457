import { Country } from '../../store/reducers/appSlice';
import store from '../../store/store';

function getCountryByDialCode(dialCode: string): Country | undefined {
  const countries = store.getState().app?.countries?.available;
  const country = Object.values(countries).find(
    (country) => country.dialCode === `+${dialCode}`
  );
  return country ?? undefined;
}

export default getCountryByDialCode;
