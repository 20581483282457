import { AnimatePresence, motion } from 'framer-motion';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useState } from 'react';
import '../../styles/pages/resetPassword.scss';
import { useTranslation } from 'react-i18next';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';
import AINormalInput from '../../ui/inputs/AINormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { PASSWORD_CHANGE_ENDPOINT } from '../../lib/constants/central-api';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useLocation, useNavigate } from 'react-router-dom';
import useTitle from '../../customHooks/useTitle';
import adUpLogoDark from '../../assets/adup_merchant_logo_dark.png';
import adUpLogoLight from '../../assets/adup_merchant_logo_light.png';
import PasswordValidationView from './PasswordValidationView';

interface ResetPasswordState {
  token: string;
}

const ResetPassword = () => {
  const { t } = useTranslation();
  useTitle(t('ResetPasswordMainText'));
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as ResetPasswordState | undefined;
  const token = state?.token;

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const userEmail = useSelector((state: RootState) => state.user.profile.email);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);

  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasLowercase, setHasLowercase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false);
  const [isLongEnough, setIsLongEnough] = useState<boolean>(false);

  const validatePassword = (password: string) => {
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecialChar(/[^a-zA-Z0-9]/.test(password));
    setIsLongEnough(password.length >= 8);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const isDisabled =
    !hasUppercase ||
    !hasLowercase ||
    !hasNumber ||
    !hasSpecialChar ||
    !isLongEnough ||
    password !== confirmPassword;

  async function onSubmit(e: any) {
    e.preventDefault();
    if (!token || token === '') return;

    const sendingData = {
      email: userEmail,
      password,
      password_confirmation: confirmPassword,
      token: token,
    };
    try {
      const requestInfo: RequestInfo = {
        url: PASSWORD_CHANGE_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'PASSWORD_CHANGE';
      const customMsg = 'Password Changed Successfully!';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;
      if (res.actionType === actionType && response.success === true) {
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  return (
    <motion.div {...pageSlideOutForward} className='resetPassword'>
      <form onSubmit={onSubmit} className='resetPassword__left'>
        <div className='resetPassword__logo'>
          <RenderLoginPageLogo
            staticURL={staticURL}
            merchantTheme={merchantTheme}
            isDarkMode={isDarkMode}
          />
        </div>
        <div className='resetPassword__content'>
          <div className='resetPassword__left--top'>
            <h1>{t('ResetPasswordMainText')}</h1>
          </div>
          <div className='resetPassword__left--center'>
            <div className='resetPassword__left--center-password'>
              <AINormalInput
                id='forgot-password-form-password'
                value={password}
                type='password'
                changeListeners={[(e) => handleChange(e)]}
                placeholder={t('NewPassword')}
                innerPlaceholder={t('EnterYourNewPassword')}
                required={true}
                focusListeners={[
                  (e) => {
                    setIsPasswordInputFocused(true);
                  },
                ]}
                blurListeners={[
                  (e) => {
                    setIsPasswordInputFocused(false);
                  },
                ]}
              />

              <AnimatePresence exitBeforeEnter>
                {isPasswordInputFocused &&
                  PasswordValidationView(
                    hasUppercase,
                    hasLowercase,
                    hasNumber,
                    hasSpecialChar,
                    isLongEnough
                  )}
              </AnimatePresence>
            </div>

            <AINormalInput
              id='forgot-password-form-password'
              value={confirmPassword}
              type='password'
              changeListeners={[(e) => setConfirmPassword(e.target.value)]}
              focusListeners={[]}
              placeholder={t('ConfirmPassword')}
              innerPlaceholder={t('ConfirmYourNewPassword')}
              required={true}
            />
          </div>

          <div className='resetPassword__left--bottom'>
            <div
              title={isDisabled ? t('ConfirmPasswordDoNotMatch') : ''}
              className='resetPassword__left--bottom-button'
            >
              <MainSquareButton
                value={t('ChangePassword')}
                type='submit'
                disabled={isDisabled}
                classes='mainSquareButton-extra-1'
              />
            </div>
          </div>
        </div>
      </form>

      <div className='resetPassword__right'>
        <div className='resetPassword__right--image'>
          {merchantTheme?.merchant_logo_light &&
          merchantTheme?.merchant_logo_light !== '' ? (
            <img
              src={`${staticURL}/medium/${
                isDarkMode
                  ? merchantTheme?.merchant_logo_light
                  : merchantTheme?.merchant_logo_dark
              }`}
              alt={'logo'}
            />
          ) : (
            <img src={isDarkMode ? adUpLogoLight : adUpLogoDark} alt={'logo'} />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ResetPassword;
