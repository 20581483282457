import '../../styles/buttons/mainSquareButton.scss';

type props = {
  onClick?: (event: React.MouseEvent) => void;
  icon?: any;
  image?: any;
  value: string;
  classes?: string;
  loading?: boolean;
  disabled?: boolean;
  normal?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonType?: 'danger' | 'warning' | 'success' | 'light' | 'filter';
  form?: string;
  message?: string;
};

const MainSquareButton: React.FC<props> = ({
  onClick,
  icon,
  image,
  value,
  type,
  disabled,
  classes = '',
  normal,
  buttonType,
  form,
  message,
}) => {
  let isNormalButton;
  if (normal) isNormalButton = 'true';
  else isNormalButton = 'false';

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={'mainSquareButton' + (' ' + classes)}
      data-main-square-button-normal={isNormalButton}
      data-main-square-button-type={buttonType}
      form={form}
      title={disabled ? message : ''}
    >
      {icon}
      {!icon && image && <img src={image} alt={value} />}
      <p>{value}</p>
    </button>
  );
};

export default MainSquareButton;
