import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { RECOMMENDATIONS_API } from '../../constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../utils/body-builder';

export default async function set_recommendation_api_call(
  recommendationID: string,
  action: 'select' | 'unselect' | 'pause' | 'resume' | 'reject' | 'unarchive'
) {
  const accessToken = store.getState().user?.stats?.ai_tracker_access_token;

  const requestInfo: RequestInfo = {
    url: `${RECOMMENDATIONS_API}/${action}`,
    method: 'PATCH',
    body: applicationWWWXRLEncodedBodyBuilder({
      id: recommendationID,
    }),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const actionType = 'PATCH_RECOMMENDATIONS';
  const customMsg = 'Recommendation updated successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  return response;
}
