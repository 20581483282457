import { motion } from 'framer-motion';
import { tickSVG, crossSVG } from '../../lib/allCustomSVGs';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { t } from 'i18next';

const PasswordValidationView = (
  hasUppercase: boolean,
  hasLowercase: boolean,
  hasNumber: boolean,
  hasSpecialChar: boolean,
  isLongEnough: boolean
) => {
  return (
    <motion.div
      initial={{ opacity: 0.1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.1 }}
      transition={{ duration: 0.3 }}
      className='resetPassword__left--validation'
    >
      <div className='resetPassword__left--validation-item'>
        <div className='resetPassword__left--validation-item-icon'>
          {hasUppercase ? (
            <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
          ) : (
            <div className='resetPassword__left--validation-item-icon-x'>
              <div className='resetPassword__left--validation-item-icon-x'>
                <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
              </div>
            </div>
          )}
        </div>
        <p>{t('AtLeastOneUppercaseLetter')}</p>
      </div>
      <div className='resetPassword__left--validation-item'>
        <div className='resetPassword__left--validation-item-icon'>
          {hasLowercase ? (
            <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
          ) : (
            <div className='resetPassword__left--validation-item-icon-x'>
              <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
            </div>
          )}
        </div>
        <p>{t('AtLeastOneLowercaseLetter')}</p>
      </div>
      <div className='resetPassword__left--validation-item'>
        <div className='resetPassword__left--validation-item-icon'>
          {hasNumber ? (
            <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
          ) : (
            <div className='resetPassword__left--validation-item-icon-x'>
              <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
            </div>
          )}
        </div>
        <p>{t('AtLeastOneNumber')}</p>
      </div>
      <div className='resetPassword__left--validation-item'>
        <div className='resetPassword__left--validation-item-icon'>
          {hasSpecialChar ? (
            <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
          ) : (
            <div className='resetPassword__left--validation-item-icon-x'>
              <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
            </div>
          )}
        </div>
        <p>{t('AtLeastOneSpecialCharacter')}</p>
      </div>
      <div className='resetPassword__left--validation-item'>
        <div className='resetPassword__left--validation-item-icon'>
          {isLongEnough ? (
            <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
          ) : (
            <div className='resetPassword__left--validation-item-icon-x'>
              <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
            </div>
          )}
        </div>
        <p>{t('AtLeast8Characters')}</p>
      </div>
    </motion.div>
  );
};

export default PasswordValidationView;
