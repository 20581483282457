import { useTranslation } from 'react-i18next';
import '../../../../styles/components/charts/chart.scss';
import { useEffect, useState } from 'react';
import LocationBasedConversionRatePieChart from './LocationBasedConversionRatePieChart';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import SingleCountryConversionRatePieChart from './SingleCountryConversionRatePieChart';
import { useSelector } from 'react-redux';
import ChartWithConfig from '../../ChartWithConfig';

type Props = {
  timeFilter: string;
};

const MainOverallAndLocationBasedCRChart = ({ timeFilter }: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=conversion_rate&time_range=all&group_by=location'
  );
  const [response, setResponse] = useState<any>({ type: 'location', data: {} });
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  //Selected country
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const renderCharts = () => {
    if (selectedCountry === '') {
      return (
        <LocationBasedConversionRatePieChart
          apiResponse={response}
          setSelectedCountry={setSelectedCountry}
        />
      );
    } else if (selectedCountry !== '') {
      return <SingleCountryConversionRatePieChart apiResponse={response} />;
    }
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (selectedCountry && selectedCountry !== '') {
          setResponse({ type: 'country', data: res });
        } else {
          setResponse({ type: 'location', data: res });
        }
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    if (selectedCountry === '') {
      setTitle(t('LocationBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnLocation'));
    } else if (selectedCountry !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedCountry}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedCountry}`);
    }
  }, [timeFilter, selectedCountry]);

  useEffect(() => {
    setResponse({ type: 'load', data: [] });

    if (selectedCountry && selectedCountry !== '') {
      setParams(
        `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=location&group_by_value=${selectedCountry}`
      );
    } else {
      setParams(
        `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=location`
      );
    }
  }, [timeFilter, selectedCountry]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const onBackButtonClick = () => {
    if (selectedCountry !== '') {
      setSelectedCountry('');
    }
  };

  useEffect(() => {
    if (selectedCountry !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedCountry]);

  return (
    <ChartWithConfig
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainOverallAndLocationBasedCRChart;
