import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';

type Props = {
  apiResponse: any;
  groupBy: string;
};
const AverageCartValueLineChart = ({ apiResponse, groupBy }: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [xAxisKey, setXAxisKey] = useState<string>('month');

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');

    if (res !== 'initial' && Object.keys(res)?.length === 0) {
      setLoading('null');
      return;
    }
    if (res !== 'initial' && Object.keys(res)?.length > 0) {
      setChartData(res);
      setLoading('success');
    }
  };

  useEffect(() => {
    if (groupBy === 'last_3_months') {
      setXAxisKey('month');
    } else if (groupBy === 'last_4_weeks') {
      setXAxisKey('week');
    } else if (groupBy === 'last_7_days') {
      setXAxisKey('day');
    }
  }, [groupBy]);

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (value: number | null) => `${value}€`;

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <>
          <div className='chart__bottom--content-chart'>
            <div
              style={{ width: '100%' }}
              className='chart__bottom--content-chart-pie'
            >
              <LineChart
                dataset={chartData}
                xAxis={[{ scaleType: 'band', dataKey: `${xAxisKey}` }]}
                yAxis={[
                  {
                    label: 'Cart Value (€)',
                    // min: 0,
                  },
                ]}
                series={[{ dataKey: 'average_cart_value', valueFormatter }]}
                height={290}
                colors={['#8884d8']}
                margin={{ left: 50, right: 30, top: 30, bottom: 40 }}
                grid={{ vertical: true, horizontal: true }}
                sx={(theme) => ({
                  [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#8b909a',
                      strokeWidth: 3,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                      fill: '#8b909a',
                    },
                  },
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    fill: '#8b909a',
                    // transform: 'translate(-10px, -10px)',
                  },
                  [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                    fill: '#8b909a', // Change color of x-axis label
                  },
                  [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                    fill: '#8b909a', // Change color of x-axis tick labels
                  },
                })}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AverageCartValueLineChart;
