import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import '../../styles/components/subPageContentHolder/customTimeRangePicker.scss';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

type Props = {
  toggleCustomTimePopupModal: () => void;
};

// Custom theme for dark mode
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
// Custom theme for light mode
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const CustomTimeRangePicker = ({ toggleCustomTimePopupModal }: Props) => {
  const { t } = useTranslation();
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const [message, setMessage] = useState<string>('');
  const [dateString, setDateString] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
  };

  const handleDoneClick = () => {
    if (!startDate) {
      setMessage(t('PleaseSelectStartDate'));
    } else if (!endDate) {
      setMessage(t('PleaseSelectEndDate'));
    } else if (startDate > endDate) {
      setMessage(t('StartDateShouldBeLessThanEndDate'));
    } else {
      setMessage('');
      const formattedStartDate = startDate.format('YYYY-MM-DD');
      const formattedEndDate = endDate.format('YYYY-MM-DD');
      const urlString = `start=${formattedStartDate}&end=${formattedEndDate}`;
      setDateString(urlString);
      console.log('Date String for URL:', urlString);
      toggleCustomTimePopupModal();
    }
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className={`customTimeRangePicker ${isDarkMode ? 'dark-mode' : ''}`}
        >
          <div className='customTimeRangePicker__text'>
            <h3>{t('SelectDateRange')}</h3>
          </div>

          <div className='customTimeRangePicker__dates'>
            <DatePicker
              label={t('StartDate')}
              value={startDate}
              onChange={handleStartDateChange}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: '1.2rem', // Increase font size of the DatePicker input (value area)
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1.5rem', // Increase font size of the label
                },
                '& .MuiInputBase-input': {
                  fontSize: '1.2rem', // Increase font size of the input field (this includes the placeholder text)
                },
              }}
            />
            <span>-</span>
            <DatePicker
              label={t('EndDate')}
              value={endDate}
              onChange={handleEndDateChange}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: '1.2rem', // Increase font size of the DatePicker input (value area)
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1.5rem', // Increase font size of the label
                },
                '& .MuiInputBase-input': {
                  fontSize: '1.2rem', // Increase font size of the input field (this includes the placeholder text)
                },
              }}
            />
          </div>

          <div className='customTimeRangePicker__message'>
            {message && <p>{message}</p>}
          </div>

          <div className='customTimeRangePicker__btn'>
            <MainSquareButton value={t('Select')} onClick={handleDoneClick} />
          </div>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CustomTimeRangePicker;
