import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserDemographicsMapChart from './UserDemographicsMapChart';
import ChartWithConfig from '../../../../../components/charts/ChartWithConfig';

const MainUserDemographicsChart = () => {
  const { t } = useTranslation();

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('all');

  const groupByOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: 'last_3_months', OptionName: t('Last3Months') },
  ];

  const [title, setTitle] = useState<string>(t('UserDemographics'));
  const [comment, setComment] = useState<string>(t('UserDemographicsComment'));

  const data = [
    { id: 'PAK', name: 'Pakistan', count: 552097 },
    { id: 'POL', name: 'Poland', count: 872322 },
    { id: 'USA', name: 'United States', count: 119103 },
    { id: 'AUS', name: 'Australia', count: 13442 },
    { id: 'ZAF', name: 'South Africa', count: 13333 },
    { id: 'JPN', name: 'Japan', count: 2753 },
    { id: 'ARG', name: 'Argentina', count: 886 },
    {
      id: 'NLD',
      name: 'Netherlands',
      count: 15472,
    },
    {
      id: 'LKA',
      name: 'Sri Lanka',
      count: 39425,
    },
    {
      id: 'GBR',
      name: 'United Kingdom',
      count: 52123,
    },
  ];

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      title={title}
      comment={comment}
      isHeightAuto={true}
    >
      <UserDemographicsMapChart apiResponse={data} groupBy={groupBy} />
    </ChartWithConfig>
  );
};

export default MainUserDemographicsChart;
