import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeOfDayCRLineChart from './TimeOfDayCRLineChart';
import DayOfWeekCRLineChart from './DayOfWeekCRLineChart';
import ChartWithConfig from '../../../../../../components/charts/ChartWithConfig';
import {
  conversionRateByDayOfWeekDummyData,
  conversionRateByTimeOfDayDummyData,
} from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';

type Props = {
  timeFilter: string;
};

const MainTimeAndDayCRChart = ({ timeFilter }: Props) => {
  const { t } = useTranslation();
  const [params, setParams] = useState<string>(
    `?graph_type=conversion_rate&group_by=${timeFilter}&time_range=all`
  );
  const [response, setResponse] = useState<any>('initial');

  const [title, setTitle] = useState<string>(t('ConversionRateByTimeOfDay'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    if (timeFilter === 'time_of_day') {
      setResponse({
        type: 'TimeOfDay',
        data: conversionRateByTimeOfDayDummyData,
      });
    } else {
      setResponse({
        type: 'DayOfWeek',
        data: conversionRateByDayOfWeekDummyData,
      });
    }
  };

  useEffect(() => {
    setResponse('initial');
    setParams(
      `?graph_type=conversion_rate&group_by=${timeFilter}&time_range=all`
    );
    if (timeFilter === 'time_of_day') {
      setTitle(t('ConversionRateByTimeOfDay'));
    } else if (timeFilter === 'day_of_week') {
      setTitle(t('ConversionRateByDayOfWeek'));
    }
  }, [timeFilter]);

  useEffect(() => {
    getGraphData();
  }, [params]);

  function renderChart() {
    if (timeFilter === 'time_of_day') {
      return <TimeOfDayCRLineChart apiResponse={response} />;
    } else {
      return <DayOfWeekCRLineChart apiResponse={response} />;
    }
  }

  return <ChartWithConfig title={title}>{renderChart()}</ChartWithConfig>;
};

export default MainTimeAndDayCRChart;
