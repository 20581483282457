import { useEffect, useState } from 'react';
import ChartWithConfig from '../ChartWithConfig';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import UserDemographicsMapChart from './UserDemographicsMapChart';

type Props = {
  timeFilter: string;
};

const MainUserDemographicsChart = ({ timeFilter }: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=user_demography_graph&time_range=all'
  );

  const [response, setResponse] = useState<any>('initial');

  const [title, setTitle] = useState<string>(t('UserDemographics'));
  const [comment, setComment] = useState<string>(t('UserDemographicsComment'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        setResponse(res);
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    setResponse('initial');
    setParams(`?graph_type=user_demography_graph&time_range=${timeFilter}`);
  }, [timeFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats]);

  return (
    <ChartWithConfig isHeightAuto={true} title={title} comment={comment}>
      <UserDemographicsMapChart apiResponse={response} />
    </ChartWithConfig>
  );
};

export default MainUserDemographicsChart;
