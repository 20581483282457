import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import DeviceConversionRateBarChart from './DeviceConversionRateBarChart';
import SingleDeviceConversionRateBarChart from './SingleDeviceConversionRateBarChart';
import {
  conversionRateOfDesktopDummyData,
  conversionRateOfMobileDummyData,
  deviceBasedConversionRateDummyData,
} from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';
import ChartWithConfig from '../../../../../../components/charts/ChartWithConfig';

const MainDeviceBasedCRChart = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<string>(
    '?graph_type=conversion_rate&time_range=all&group_by=device'
  );

  const [response, setResponse] = useState<any>({
    type: 'overallDevices',
    data: {},
  });

  //Selected country
  const [selectedDevice, setSelectedDevice] = useState<string>('');

  //Comments and titles
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  const renderCharts = () => {
    if (selectedDevice === '') {
      return (
        <DeviceConversionRateBarChart
          apiResponse={response}
          setSelectedDevice={setSelectedDevice}
        />
      );
    } else if (selectedDevice !== '') {
      return <SingleDeviceConversionRateBarChart apiResponse={response} />;
    }
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    if (selectedDevice && selectedDevice !== '') {
      setResponse({
        type: 'singleDevice',
        data:
          selectedDevice === 'Desktop'
            ? conversionRateOfDesktopDummyData
            : conversionRateOfMobileDummyData,
      });
    } else {
      setResponse({
        type: 'overallDevices',
        data: deviceBasedConversionRateDummyData,
      });
    }
  };

  useEffect(() => {
    if (selectedDevice === '') {
      setTitle(t('DeviceBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnDevice'));
    } else if (selectedDevice !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedDevice}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedDevice}`);
    }
  }, [selectedDevice]);

  useEffect(() => {
    setResponse({ type: 'load', data: [] });
    if (selectedDevice && selectedDevice !== '') {
      setParams(
        `?graph_type=conversion_rate&time_range=all&group_by=device&group_by_value=${selectedDevice}`
      );
    } else {
      setParams(`?graph_type=conversion_rate&time_range=all&group_by=device`);
    }
  }, [selectedDevice]);

  useEffect(() => {
    getGraphData();
  }, [params]);

  const onBackButtonClick = () => {
    if (selectedDevice !== '') {
      setSelectedDevice('');
    }
  };

  useEffect(() => {
    if (selectedDevice !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedDevice]);

  return (
    <ChartWithConfig
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainDeviceBasedCRChart;
