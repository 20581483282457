import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/settings/settingsSecurityQR.scss';
import { HOME_ROUTE } from '../../../lib/constants/route';
import AINormalInput from '../../../ui/inputs/AINormalInput';
import AIPhoneInput from '../../../ui/inputs/AIPhoneInput';
import { useDispatch, useSelector } from 'react-redux';
import getCountryByDialCode from '../../../lib/helper-functions/getCountryByDialCode';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';
import PasswordValidationView from '../../forgotPassword/PasswordValidationView';
import { asyncHandler } from '../../../lib/api/async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import {
  LOGIN_API,
  PASSWORD_CHANGE_ENDPOINT,
  REGISTRATION_API,
} from '../../../lib/constants/central-api';
import {
  applicationJSONBodyBuilder,
  applicationWWWXRLEncodedBodyBuilder,
} from '../../../lib/utils/body-builder';
import { updateProfile } from '../../../store/reducers/userSlice';
import { submitLogToBugsnag } from '../../../lib/api/log';
import { setMessage } from '../../../store/reducers/appSlice';

const SettingsProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const IPCountry = useSelector(
    (state: RootState) => state.app.countries.countryOfIP
  );
  const countries = useSelector(
    (state: RootState) => state.app.countries.available
  );
  const seller_id = useSelector(
    (state: RootState) => state.user.profile.seller_id
  );
  const merchantSlug = useSelector(
    (state: RootState) => state.user.merchant.merchant_slug
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth?.authorization?.accessToken
  );
  const isWhiteLabel = useSelector(
    (state: RootState) => state.UI?.isWhiteLabel
  );
  const userProfile = useSelector((state: RootState) => state.user.profile);

  const [firstName, setFirstName] = useState(userProfile?.first_name || '');
  const [lastName, setLastName] = useState(userProfile?.last_name || '');
  const [email, setEmail] = useState(userProfile?.email || '');
  const [phoneNumber, setPhoneNumber] = useState({
    number: userProfile.phone,
    country: userProfile.country_code
      ? getCountryByDialCode(userProfile.country_code)
      : countries[IPCountry],
  });

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);

  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasLowercase, setHasLowercase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false);
  const [isLongEnough, setIsLongEnough] = useState<boolean>(false);

  const [isSubmittingProfile, setIsSubmittingProfile] =
    useState<boolean>(false);
  const isDisabledProfile =
    !isNotEmptyString(firstName) ||
    !isNotEmptyString(lastName) ||
    !isNotEmptyString(email) ||
    !isNotEmptyString(phoneNumber.number)
      ? true
      : false;

  const [isSubmittingPassword, setIsSubmittingPassword] =
    useState<boolean>(false);
  const isDisabledPassword =
    !hasUppercase ||
    !hasLowercase ||
    !hasNumber ||
    !hasSpecialChar ||
    !isLongEnough ||
    newPassword !== confirmPassword;

  /* ----------------------------API CALLs START---------------------------- */
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  async function submitProfile(e: any) {
    e.preventDefault();
    setIsSubmittingProfile(true);

    const sendingData = {
      first_name: isNotEmptyString(firstName) ? firstName : '',
      last_name: isNotEmptyString(lastName) ? lastName : firstName[0],
      contact: phoneNumber.number,
      country_code: phoneNumber.country?.dialCode?.substring(1),
      email: email,
      language: userProfile?.language,
    };

    try {
      const requestInfo: RequestInfo = {
        url: `${REGISTRATION_API(merchantSlug)}/${seller_id}`,
        method: 'PUT',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'PROFILE_INFO_UPDATE';
      const customMsg = `${t('ProfileInfoUpdatedSuccessfully')}`;

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        true,
        false
      );

      const response = res.data;
      if (res.actionType === actionType && response.success === true) {
        const { user } = response?.data;

        if (user?.role[0]?.name === 'seller') {
          if (user?.seller_id) {
            dispatch(
              updateProfile({
                first_name: firstName,
                last_name: lastName,
                country_code: phoneNumber.country?.dialCode?.substring(1),
                phone: phoneNumber.number,
                language: userProfile?.language,
                email: email,
              })
            );
            setIsSubmittingProfile(false);
          }
        }
      } else {
        setIsSubmittingProfile(false);
      }
    } catch (exception) {
      setIsSubmittingProfile(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function submitPasswordChange(e: any) {
    e.preventDefault();
    setIsSubmittingPassword(true);

    let isCurrentPasswordVerified = false;

    try {
      const requestInfo: RequestInfo = {
        url: LOGIN_API,
        method: 'POST',
        body: applicationJSONBodyBuilder({
          email: email,
          password: currentPassword,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'CURRENT_PASSWORD_VERIFICATION';
      const customMsg = 'Current Password Verified Successfully!';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        true
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        if (response?.data?.access_token) {
          isCurrentPasswordVerified = true;
        } else {
          isCurrentPasswordVerified = false;
        }
      } else {
        isCurrentPasswordVerified = false;
      }
    } catch (exception) {
      isCurrentPasswordVerified = false;
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }

    if (isCurrentPasswordVerified) {
      const sendingData = {
        email: email,
        password: newPassword,
        password_confirmation: confirmPassword,
        token: isWhiteLabel
          ? accessToken.currentWhiteLabelSellerToken
          : accessToken.sellerToken,
      };
      try {
        const requestInfo: RequestInfo = {
          url: PASSWORD_CHANGE_ENDPOINT,
          method: 'POST',
          body: applicationWWWXRLEncodedBodyBuilder(sendingData),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: `${accessToken.type} ${
              isWhiteLabel
                ? accessToken.currentWhiteLabelSellerToken
                : accessToken.sellerToken
            }`,
          },
        };

        const actionType = 'PASSWORD_CHANGE';
        const customMsg = `${t('PasswordChangedSuccessfully')}`;

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;
        if (res.actionType === actionType && response.success === true) {
          setIsSubmittingPassword(false);
        }
      } catch (exception) {
        setIsSubmittingPassword(false);
        console.log('Error during authentication request');
      }
    } else {
      setIsSubmittingPassword(false);
      dispatch(
        setMessage({
          message: t('InvalidCurrentPassword'),
          messageType: 'error',
        })
      );
    }
  }
  /* ----------------------------API CALLs END---------------------------- */

  const validatePassword = (password: string) => {
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecialChar(/[^a-zA-Z0-9]/.test(password));
    setIsLongEnough(password.length >= 8);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
  };

  return (
    <SubPageInnerContentHolder
      title={t('ProfileSettings')}
      subTitle={t('ManageProfileSettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {/*------------------ Profile Information ------------------*/}
        <form
          id='profile-information-settings-form'
          className='normalForm'
          onSubmit={submitProfile}
        >
          <div className='normalForm__top'>
            {/* <hr /> */}
            <div
              className='aiOnboardingCompany__title'
              style={{ marginBottom: '0rem' }}
            >
              <span>{t('ProfileInformationSettings')}</span>
            </div>
            <h1>{t('ManageProfileInformationSettings')}</h1>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div className='aiOnboardingCompany__input'>
                  <AINormalInput
                    changeListeners={[
                      (e) => {
                        setFirstName(e.target.value);
                      },
                    ]}
                    id='profileSettings-first-name-input'
                    value={firstName}
                    placeholder={t('FirstName')}
                    pattern='^.{1,30}$'
                    required={true}
                    title={t('Name-validation')}
                    innerPlaceholder={t('EnterYourFirstName')}
                    minlength={1}
                    maxlength={30}
                  />
                </div>
              </div>
              <div className='normalForm__container1--right'>
                <div className='aiOnboardingCompany__input'>
                  <AINormalInput
                    changeListeners={[
                      (e) => {
                        setLastName(e.target.value);
                      },
                    ]}
                    id='profileSettings-last-name-input'
                    value={lastName}
                    placeholder={t('LastName')}
                    pattern='^.{1,30}$'
                    required={true}
                    title={t('Name-validation')}
                    innerPlaceholder={t('EnterYourLastName')}
                    minlength={1}
                    maxlength={30}
                  />
                </div>
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div className='aiOnboardingCompany__input'>
                  <AINormalInput
                    changeListeners={[
                      (e) => {
                        setEmail(e.target.value);
                      },
                    ]}
                    id='sign-up-form-email'
                    value={email}
                    placeholder={t('EmailAddress')}
                    pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                    required={true}
                    title={t('Email-validation')}
                    innerPlaceholder={t('EnterYourEmailAddress')}
                    type='email'
                  />
                </div>
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div className='aiOnboardingCompany__input'>
                  <AIPhoneInput
                    id='profileSettings-phone-input'
                    setPhoneNumber={setPhoneNumber}
                    phoneNumber={phoneNumber}
                    label={t('PhoneNumber')}
                    placeholder={t('EnterPhoneNumber')}
                    title={t('Phone-validation')}
                    required={true}
                    pattern='^[0-9]{4,}$'
                  />
                </div>
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div
                  className='normalForm__bottom flex-start'
                  style={{ marginTop: '4rem' }}
                >
                  <MainSquareButton
                    value={
                      isSubmittingProfile
                        ? `${t('Updating')}...`
                        : t('UpdateProfileBtn')
                    }
                    disabled={isDisabledProfile || isSubmittingProfile}
                    type='submit'
                    form='profile-information-settings-form'
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        {/*------------------ Change Password ------------------*/}
        <form
          id='profile-password-settings-form'
          className='normalForm'
          onSubmit={submitPasswordChange}
        >
          <div className='normalForm__top'>
            <br />
            <hr />
            <div
              className='aiOnboardingCompany__title'
              style={{ marginTop: '2rem', marginBottom: '0rem' }}
            >
              <span>{t('ProfilePasswordSettings')}</span>
            </div>
            <h1>{t('ManageProfilePasswordSettings')}</h1>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div className='aiOnboardingCompany__input'>
                  <AINormalInput
                    changeListeners={[
                      (e) => {
                        setCurrentPassword(e.target.value);
                      },
                    ]}
                    id='profileSettings-current-password-input'
                    value={currentPassword}
                    placeholder={t('CurrentPassword')}
                    required={true}
                    innerPlaceholder={t('EnterCurrentPassword')}
                    type='password'
                  />
                </div>
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div className='resetPassword__left--center-password'>
                  <AINormalInput
                    id='profileSettings-new-password-input'
                    value={newPassword}
                    type='password'
                    changeListeners={[(e) => handlePasswordChange(e)]}
                    placeholder={t('NewPassword')}
                    innerPlaceholder={t('EnterYourNewPassword')}
                    required={true}
                    focusListeners={[
                      (e) => {
                        setIsPasswordInputFocused(true);
                      },
                    ]}
                    blurListeners={[
                      (e) => {
                        setIsPasswordInputFocused(false);
                      },
                    ]}
                  />
                  <AnimatePresence exitBeforeEnter>
                    {isPasswordInputFocused &&
                      PasswordValidationView(
                        hasUppercase,
                        hasLowercase,
                        hasNumber,
                        hasSpecialChar,
                        isLongEnough
                      )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <AINormalInput
                  id='profileSettings-new-confirm-password-input'
                  value={confirmPassword}
                  type='password'
                  changeListeners={[(e) => setConfirmPassword(e.target.value)]}
                  focusListeners={[]}
                  placeholder={t('ConfirmPassword')}
                  innerPlaceholder={t('ConfirmYourNewPassword')}
                  required={true}
                />
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <div
                  className='normalForm__bottom flex-start'
                  style={{ marginTop: '4rem', marginBottom: '3rem' }}
                >
                  <MainSquareButton
                    value={
                      isSubmittingPassword
                        ? `${t('Updating')}...`
                        : t('ChangePassword')
                    }
                    disabled={isDisabledPassword || isSubmittingPassword}
                    type='submit'
                    form='profile-password-settings-form'
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsProfile;
