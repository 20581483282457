import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import '../../styles/inputs/aiPhoneInput.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';
import { arrowHeadLeftSVG, searchSmallSVG } from '../../lib/allCustomSVGs';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Country } from '../../store/reducers/appSlice';

type Props = {
  id: string;
  placeholder: string;
  label: string;
  classes?: string;
  setPhoneNumber: (phoneNumber: {
    number: string;
    country: Country | undefined;
  }) => void;
  phoneNumber: { number: string; country: Country | undefined };
  pattern?: string;
  title?: string;
  required?: boolean;
};

const AIPhoneInput: React.FC<Props> = ({
  id,
  placeholder,
  label,
  setPhoneNumber,
  phoneNumber,
  required,
  pattern,
  title,
}) => {
  const { t } = useTranslation();
  const countries = useSelector(
    (state: RootState) => state.app.countries.available
  );
  const [isCountrySelectorOpen, setIsCountrySelectorOpen] =
    useState<boolean>(false);

  const [filteredCountries, setFilteredCountries] = useState<any>(
    Object.keys(countries)
  );
  const [focused, setFocused] = useState<boolean>(false);
  const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setOnlyOnFocus(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    setOnlyOnFocus(false);
  };

  const onSearchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // user should be able to search with country name or dial code
    const searchValue = e.target.value;

    const filteredCountries = Object.keys(countries)?.filter((countryKey) => {
      const country = countries[countryKey];
      return (
        country?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        country?.dialCode?.includes(searchValue)
      );
    });

    setFilteredCountries(filteredCountries);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only backspace, delete, tab, and numbers
    if (
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'Tab' &&
      !(event.key >= '0' && event.key <= '9')
    ) {
      event.preventDefault();
    }
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    // Allow only numbers in the value
    if (/^\d*$/.test(newValue) || newValue === '') {
      setPhoneNumber({
        number: newValue,
        country: phoneNumber ? phoneNumber?.country : undefined,
      });
    }
  };

  return (
    <div className='aiPhoneInput-outer'>
      <label htmlFor={id}>{label}</label>
      <div className='aiPhoneInput'>
        <div
          onClick={() => setIsCountrySelectorOpen(!isCountrySelectorOpen)}
          className='aiPhoneInput__select'
        >
          <div className='aiPhoneInput__select--country'>
            <div className='aiPhoneInput__select--country-country'>
              {phoneNumber && phoneNumber?.country?.flag}
            </div>
            <div className='aiPhoneInput__select--country-code'>
              {phoneNumber && phoneNumber?.country?.dialCode}
            </div>
          </div>
          <div
            data-is-country-window-open={isCountrySelectorOpen?.toString()}
            className='aiPhoneInput__select--icon'
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
          </div>
        </div>
        <div className='aiPhoneInput-input'>
          <input
            id={id}
            type='text'
            value={phoneNumber ? phoneNumber?.number : ''}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            required={required}
            onFocus={handleFocus}
            onBlur={handleBlur}
            data-focused={focused?.toString()}
            pattern={pattern}
            title={title}
            style={{ fontFamily: 'var(--main-font-secondary)' }}
            onChange={handleNumberChange}
          />
        </div>
      </div>
      {/* <div className='aiPhoneInput-validation-text'>{onlyOnFocus && title}</div> */}
      <AnimatePresence exitBeforeEnter>
        {isCountrySelectorOpen && (
          <motion.div
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.3 }}
            className='aiPhoneInput__window'
          >
            <div className='aiPhoneInput__window--search'>
              <div className='aiPhoneInput__window--search-icon'>
                <CustomSVGs svg={searchSmallSVG} />
              </div>
              <input
                autoFocus
                onChange={onSearchChangeHandler}
                type='text'
                placeholder={t('SearchCountries')}
                onBlur={() => setIsCountrySelectorOpen(false)}
              />
            </div>
            <div className='aiPhoneInput__window--countries'>
              {filteredCountries?.map((countryKey: any, key: number) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      setPhoneNumber({
                        number: phoneNumber ? phoneNumber?.number : '',
                        country: countries[countryKey],
                      });
                      setIsCountrySelectorOpen(false);
                      setFilteredCountries(Object.keys(countries));
                    }}
                    className='aiPhoneInput__window--countries-country'
                  >
                    <div className='aiPhoneInput__window--countries-country-flag'>
                      {countries[countryKey]?.flag}
                    </div>
                    <div className='aiPhoneInput__window--countries-country-name'>
                      {countries[countryKey]?.name}
                    </div>
                    <div className='aiPhoneInput__window--countries-country-code'>
                      {countries[countryKey]?.dialCode}
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AIPhoneInput;
