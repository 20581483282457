import '../../styles/components/charts/chart.scss';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG } from '../../lib/allCustomSVGs';

type Props = {
  loading?: string;
  children: React.ReactNode;
  groupBy?: string;
  setGroupBy?: React.Dispatch<React.SetStateAction<string>>;
  timeFilter?: string;
  setTimeFilter?: React.Dispatch<React.SetStateAction<string>>;
  groupByOptionsArr?: { OptionValue: string; OptionName: string }[];
  timeFilterOptionsArr?: { OptionValue: string; OptionName: string }[];
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
  title: string;
  comment?: string;
  isConfigHidden?: boolean;
  isHeightAuto?: boolean;
};
//
const ChartWithConfig = ({
  children,
  isBackButtonVisible,
  onBackButtonClick,
  title,
  comment,
  isHeightAuto,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div style={isHeightAuto ? { height: 'auto' } : {}} className='chart'>
      <div className='chart__heading'>
        <h2 className='chart__heading--title'>{title}</h2>
        {comment && <p className='chart__heading--comment'>{comment}</p>}
        {isBackButtonVisible && (
          <div
            title={t('GoBackToPreviousChart')}
            onClick={onBackButtonClick}
            className='chart-goBack'
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
            <span>{t('GoBack')}</span>
          </div>
        )}
      </div>
      <div className='chart__bottom'>
        <div className='chart__bottom--content'>
          {/* Render Chart */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ChartWithConfig;
