import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import ChartLegend from '../../../../../../components/charts/ChartLegend';
import { overallConversionRateDummyData } from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';
import LoadingSpinner from '../../../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../../../ui/noData/NoData';

const OverallConversionRatePieChart = () => {
  const { t } = useTranslation();
  const [conversionRate, setConversionRate] = useState(0);
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    setLoading('pending');
    try {
      // Call to get conversion rate stats by location
      const res = { data: overallConversionRateDummyData };

      if (res) {
        if (Object.keys(res)?.length === 0) {
          setLoading('null');
          return;
        } else {
          getConversionRateData(res);
        }
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  const getConversionRateData = async (res: any) => {
    const totalCarts = res?.data?.total_carts ? res?.data.total_carts : 0;
    // const totalPurchases = res?.data?.total_purchases ? res?.data.total_purchases : 0;
    const conversionRate = res?.data?.conversion_rate
      ? parseFloat(res?.data?.conversion_rate?.toFixed(2))
      : 0;
    const remainingRate = parseFloat((100 - conversionRate)?.toFixed(2));

    setChartData([
      {
        label: t('Purchases'),
        value: conversionRate,
        color: '#82ca9d',
      },
      {
        label: t('RemainingCarts'),
        value: remainingRate,
        color: '#8884d8',
      },
    ]);

    if (conversionRate === 0) {
      setLoading('null');
      return;
    }

    setConversionRate(conversionRate);
    setLoading('success');
    return;
  };

  useEffect(() => {
    getGraphData();
  }, []);

  const valueFormatter = (item: { value: number }) => `${item.value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  return (
    <div className='pieChartComponent'>
      <h2 className='pieChartComponent__title'>{t('OverallConversionRate')}</h2>
      <p className='pieChartComponent__title--sub'>
        {t('TheOverallConversionRate')}
      </p>
      <div className='pieChartComponent__chart'>
        {loading === 'pending' && <LoadingSpinner />}

        {loading === 'null' && (
          <NoData
            title={t('No data available')}
            subTitle={t('NeedMoreInteractionsToGenerateStats')}
          />
        )}
        {loading === 'success' && (
          <div className='pieChartComponent__chart--container'>
            <div
              style={{ height: '23rem' }}
              className='pieChartComponent__chart--container-chart'
            >
              <PieChart
                margin={{ top: 5, bottom: 5, left: 5, right: 5 }}
                series={[
                  {
                    arcLabel: (item) => `${item.value}%`,
                    arcLabelMinAngle: 35,
                    arcLabelRadius: '60%',
                    ...data,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                  },
                }}
                slotProps={{
                  legend: {
                    hidden: true, // Set to true to hide the legend
                  },
                }}
              />
            </div>
            <div className='pieChartComponent__chart--container-legend'>
              {chartData?.map((item, index) => (
                <ChartLegend
                  key={index}
                  color={item?.color}
                  label={item.label}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverallConversionRatePieChart;
