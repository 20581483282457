import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SettingsGeneral from './settingsGeneral/SettingsGeneral';
// import SettingsSecurity from './settingsSecurity/SettingsSecurity';
import SettingsNotifications from './settingsNotifications/SettingsNotifications';
import SettingsShipping from './settingsShipping/SettingsShipping';
import ShopSettingsDesign from './settingsDesign/SettingsDesign';
import SettingsMarketing from './settingsMarketing/SettingsMarketing';
import store from '../../store/store';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import {
  settingSVG,
  designSVG,
  shipSVG,
  notificationSVG,
  marketingSVG,
  pspSvg,
  discountsSVG,
  userSettingSVG,
} from '../../lib/allCustomSVGs';
import SubPageLinkHolder from '../../components/subPageLinkHolder/SubPageLinkHolder';
import PSP from '../shop/psp/PSP';
import SettingsSecurity from './settingsSecurity/SettingsSecurity';
import SettingsConvenienceFee from './settingsConvenienceFee/SettingsConvenienceFee';
import SettingsDiscounts from './settingsDiscounts/SettingsDiscounts';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import SettingsSubscription from './settingsSubscription/SettingsSubscription';
import ShopSettingsWebhooks from './settingsWebhooks/SettingsWebhooks';
import { allRoutes } from '../../lib/constants/route';
import SettingsProfile from './settingsProfile/SettingsProfile';

const SettingsPage = () => {
  const navigate = useNavigate();
  // const subscriptionLock = useSelector(
  //   (state: RootState) => state.auth.authorization.subscriptionLock
  // );

  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const currentPath = window.location.pathname;

  /* useEffect(() => {
    if (subscriptionLock && subscriptionLock === 'true') {
      navigate('/settings/subscription');
    }
  }, [currentPath, subscriptionLock]); */

  useEffect(() => {
    if (currentPath === '/settings') {
      navigate('/settings/general');
    }
  }, [currentPath, navigate]);

  /* <SubPageLinkHolder
          mainSubTitle={t('Settings')}
          items={
            subscriptionLock && subscriptionLock === 'true'
              ? subPageLinkHolderLockedItems
              : subPageLinkHolderItems
          }
        /> */

  const subPageLinkHolderItems = [
    {
      icon: settingSVG,
      title: 'General',
      link: '/settings/general',
      urlName: 'general',
    },
    {
      icon: designSVG,
      title: 'Appearance',
      link: '/settings/appearance',
      urlName: 'appearance',
    },
    {
      icon: pspSvg,
      title: 'Payments',
      link: '/settings/payments',
      urlName: 'payments',
    },
    {
      icon: shipSVG,
      title: 'Shipping',
      link: '/settings/shipping',
      urlName: 'shipping',
    },
    {
      icon: pspSvg,
      title: 'ConvenienceFee',
      link: '/settings/convenienceFee',
      urlName: 'convenienceFee',
    },
    {
      icon: discountsSVG,
      title: 'Discounts',
      link: '/settings/discounts',
      urlName: 'discounts',
    },
    {
      icon: notificationSVG,
      title: 'Notifications',
      link: '/settings/notifications',
      urlName: 'notifications',
    },
    // { //? commented out since it is not needed in White Label
    //   icon: subscribeSVG,
    //   title: 'Subscription',
    //   link: '/settings/subscription',
    //   urlName: 'subscription',
    // },
    // {
    //   icon: webhookSVG,
    //   title: 'Webhooks',
    //   link: '/settings/webhooks',
    //   urlName: 'webhooks',
    // },
    {
      icon: marketingSVG,
      title: 'Marketing',
      link: '/settings/marketing',
      urlName: 'marketing',
    },
    /*  {
      icon: securitySVG,
      title: 'Security',
      link: '/settings/security',
      urlName: 'security',
    }, */
    {
      icon: userSettingSVG,
      title: 'Profile',
      link: '/settings/profile',
      urlName: 'profile',
    },
  ];

  return (
    <PageWrapperMain>
      {/* {activeLink === 'settings' && settingsPageContent()} */}
      <div className='subPage'>
        {isWhiteLabel && (
          <SubPageLinkHolder
            mainSubTitle={'Settings'}
            items={subPageLinkHolderItems}
          />
        )}

        <SubPageContentHolder>
          <Routes>
            {allRoutes['/settings/general'] && (
              <Route path='general/*' element={<SettingsGeneral />} />
            )}
            {allRoutes['/settings/appearance'] && (
              <Route path='appearance/*' element={<ShopSettingsDesign />} />
            )}
            {allRoutes['/settings/payments'] && (
              <Route path='payments/*' element={<PSP />} />
            )}
            {allRoutes['/settings/shipping'] && (
              <Route path='shipping/*' element={<SettingsShipping />} />
            )}
            {allRoutes['/settings/convenienceFee'] && (
              <Route
                path='convenienceFee/*'
                element={<SettingsConvenienceFee />}
              />
            )}
            {allRoutes['/settings/discounts'] && (
              <Route path='discounts/*' element={<SettingsDiscounts />} />
            )}
            {allRoutes['/settings/notifications'] && (
              <Route
                path='notifications/*'
                element={<SettingsNotifications />}
              />
            )}
            {allRoutes['/settings/subscription'] && (
              <Route path='subscription/*' element={<SettingsSubscription />} />
            )}
            {allRoutes['/settings/webhooks'] && (
              <Route path='webhooks/*' element={<ShopSettingsWebhooks />} />
            )}
            {allRoutes['/settings/marketing'] && (
              <Route path='marketing/*' element={<SettingsMarketing />} />
            )}
            {allRoutes['/settings/security'] && (
              <Route path='security/*' element={<SettingsSecurity />} />
            )}
            {allRoutes['/settings/profile'] && (
              <Route path='profile/*' element={<SettingsProfile />} />
            )}
          </Routes>
        </SubPageContentHolder>
      </div>
    </PageWrapperMain>
  );

  /*   return (
    <div className='subPage'>
     
      <SubPageContentHolder>
        <Routes>
          <Route path='general/*' element={<SettingsGeneral />} />
          <Route path='design/*' element={<ShopSettingsDesign />} />
          <Route path='shipping/*' element={<SettingsShipping />} />
          <Route path='notifications/*' element={<SettingsNotifications />} />
          <Route path='subscription/*' element={<SettingsSubscription />} />
          <Route path='webhooks/*' element={<ShopSettingsWebhooks />} />
          <Route path='marketing/*' element={<SettingsMarketing />} />
          <Route path='security/*' element={<SettingsSecurity />} />
          <Route path='profile/*' element={<SettingsProfile />} />
        </Routes>
      </SubPageContentHolder>
    </div>
  ); */
};

export default SettingsPage;
