import { useTranslation } from 'react-i18next';
import '../../../../styles/components/charts/chart.scss';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import ChartLegend from '../../ChartLegend';

type Props = {
  apiResponse: any;
};

const SingleCountryConversionRatePieChart = ({ apiResponse }: Props) => {
  const { t } = useTranslation();

  const [conversionRate, setConversionRate] = useState(0);
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');
    if (res?.type === 'country' && res?.data) {
      const totalCarts = res?.data?.total_carts ? res?.data.total_carts : 0;
      // const totalPurchases = res?.data?.total_purchases ? res?.data.total_purchases : 0;
      const conversionRate = res?.data?.conversion_rate
        ? parseFloat(res?.data?.conversion_rate?.toFixed(2))
        : 0;
      const remainingRate = parseFloat((100 - conversionRate)?.toFixed(2));

      setChartData([
        {
          label: t('Purchases'),
          value: conversionRate,
          color: '#82ca9d',
        },
        {
          label: t('RemainingCarts'),
          value: remainingRate,
          color: '#8884d8',
        },
      ]);

      if (conversionRate === 0) {
        setLoading('null');
        return;
      }

      setConversionRate(conversionRate);
      setLoading('success');
      return;
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (item: { value: number }) => `${item.value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <div className='chart__bottom--content-chart'>
          <div
            style={{ height: '25rem', width: '25rem' }}
            className='chart__bottom--content-chart-pie'
          >
            <PieChart
              margin={{ top: 5, bottom: 5, left: 5, right: 5 }}
              series={[
                {
                  arcLabel: (item) => `${item.value}%`,
                  arcLabelMinAngle: 35,
                  arcLabelRadius: '60%',
                  ...data,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fontWeight: 'bold',
                },
              }}
              slotProps={{
                legend: {
                  hidden: true, // Set to true to hide the legend
                },
              }}
            />
          </div>
          <div className='chart__bottom--content-chart-legend'>
            <div className='chart__bottom--content-chart-legend-content'>
              {chartData?.map((item: any, index: number) => (
                <ChartLegend
                  key={index}
                  color={item?.color}
                  label={item.label}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleCountryConversionRatePieChart;
