import { useDispatch, useSelector } from 'react-redux';
import '../../styles/pages/ai-onboarding/aiOnboardingMain.scss';
import AIOnboardingCompany from './AIOnboardingCompany';
import AIOnboardingProgress from './AIOnboardingProgress';
import { RootState } from '../../store/store';
import AIOnboardingIntegrations from './AIOnboardingIntegrations';
import AIOnboardingReady from './AIOnboardingReady';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../lib/constants/route';
import { setAiOnboardingInprogress } from '../../store/reducers/userSlice';
import { motion, AnimatePresence } from 'framer-motion';

const AIOnboardingMain = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(
    (state: RootState) => state.user?.ai_onboarding?.currentStep || 1
  );
  const isAiOnboardingComplete = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.is_ai_onboarding_completed || false
  );

  const [apiKey, setAPIKey] = useState<string>('');

  const navigate = useNavigate();
  useEffect(() => {
    if (isAiOnboardingComplete) {
      navigate(HOME_ROUTE);
    } else {
      dispatch(setAiOnboardingInprogress(true));
    }
  }, [isAiOnboardingComplete]);

  return (
    <div className='aiOnboardingMain'>
      <div className='aiOnboardingMain__content'>
        <div className='aiOnboardingMain__content--top'>
          <AIOnboardingProgress />
        </div>
        <div className='aiOnboardingMain__content--bottom'>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={currentStep}
              initial={{ opacity: 0.1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.1 }}
              transition={{ duration: 0.3 }}
            >
              {currentStep === 1 && <AIOnboardingCompany />}
              {currentStep === 2 && (
                <AIOnboardingIntegrations
                  apiKey={apiKey}
                  setAPIKey={setAPIKey}
                />
              )}
              {currentStep === 3 && <AIOnboardingReady />}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AIOnboardingMain;
